<script setup lang="ts">
import type { ReportTableColumn } from '../types'
import { ReportUIConfigActionType } from '@reports/constants'

const props = defineProps<ReportTableColumn>()
</script>

<template>
  <Cell v-bind="props" class="group">
    <span
      class="border-success/5 bg-success/10 group-hover:border-success/30 group-hover:bg-success/20 text-success rounded-full border px-1.5 font-semibold"
    >
      {{ value.value }}
    </span>
  </Cell>
</template>
